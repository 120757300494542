import { Language, LanguageCode } from '@/api/useLanguages.types'
import { RoleName } from '@/api/useUsers.types'

export const getStaticUserData = () => {
  const user = window.App?.HELPERS.currentUser

  return {
    companyId: user?.companyId,
    userId: user?.id,
    roleName: user?.roleName,
    isAdmin: user?.roleName === RoleName.Admin,
    language: user?.language ?? Language.English,
    languageCode: user?.language
      ? (`${user?.language}-DE` as LanguageCode)
      : LanguageCode.English,
    roleNames: window.App?.HELPERS.roleNames ?? [],
  }
}
